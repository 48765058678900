@import "./variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";

.shoppable-images-wrapper {
    .shoppable-title {
        flex-grow: 1;
        font-size: $font-32;
        line-height: 120%;

        * {
            color: inherit;
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            margin: 0;
        }
    }

    .has-tooltip {
        position: relative;
    }

    .form-field-tooltip {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .bookmark-overlay-trigger,
    .toggle-reveal {
        display: none;
    }

    .bookmarks-image-container {
        height: auto;
        position: relative;

        @include media-breakpoint-up(lg) {
            height: auto;
        }
    }

    .bookmarks-image-el {
        border-radius: $border-radius-8;
        display: block;
        height: 100%;
        left: 50%;
        position: relative;
        transform: translate(-50%, 0);
        width: 100%;

        @include media-breakpoint-up(lg) {
            height: auto;
            position: static;
            transform: none;
            width: 100%;
        }
    }

    .shoppable-images-slot-container {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
    }

    .hide {
        display: none;
    }

    .bookmarks-wrapper {
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
    }

    .image-products-tagger {
        position: relative;
    }

    .disable-link-on-image {
        pointer-events: none;
    }

    .hideOnDesktop {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }

    .hideOnMobile {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .bookmark-tag-icon {
        align-items: center;
        background: rgba(9, 11, 11, 0.6);
        border-radius: 50%;
        display: flex;
        height: $space-32;
        justify-content: center;
        transition: transform 0.5s ease;
        width: $space-32;

        .bookmark-tag-icon-circle {
            background: $color-n0;
            border-radius: 50%;
            display: block;
            height: $space-12;
            width: $space-12;
        }
    }

    .bookmark-tag {
        cursor: pointer;
        position: absolute;
        transform: translate(-0.8rem, -0.8rem);
        z-index: 1;
    }

    .bookmark-product-price {
        line-height: normal;
        margin-bottom: 0.3rem;
        margin-top: 0.3rem;
        text-align: left;
    }

    .tooltip-type-wrapper {
        .bookmark-content {
            background: $color-n0;
            border-radius: $border-radius-8;
            box-shadow: -0.063rem 0.063rem 0.25rem -0.063rem $color-t500;
            padding: $space-8 $space-16 $space-16;
            position: absolute;
            width: 196px;

            &.top-left {
                bottom: 2.188rem;
                right: 0;
            }

            &.top-center {
                bottom: 2.188rem;
                transform: translateX(calc(-50% + 1.25rem));

                &::before {
                    bottom: -0.313rem;
                    left: calc(50% - 0.438rem);
                }
            }

            &.top-right {
                bottom: 1.563rem;
                left: -1.875rem;

                &::before {
                    bottom: -0.313rem;
                    left: 0.75rem;
                }
            }

            &.middle-left {
                right: 2.813rem;
                top: -1.25rem;
            }

            &.middle-right {
                bottom: -1.375rem;
                left: 0.313rem;
            }

            &.bottom-left {
                right: 0;
                top: 2.188rem;
            }

            &.bottom-center {
                top: 2.188rem;
                transform: translateX(calc(-50% + 1.25rem));
            }

            &.bottom-right {
                top: 2.5rem;
            }
        }

        .bookmark-product-name {
            -webkit-box-orient: vertical;
            color: $color-t200;
            display: -webkit-box;
            font-size: $font-14;
            font-weight: $font-medium;
            -webkit-line-clamp: 2;
            margin: 0;
            margin-bottom: $space-4;
            max-width: 164px;
            overflow: hidden;
        }

        .tooltip-anchor {
            position: relative;
        }
    }

    .pit-body {
        overflow: hidden;
    }

    .bookmark-product-image {
        all: unset;
        margin: 0.15rem auto;
        width: 164px;
    }

    .svg-cross {
        fill: $color-n0;
    }

    .bookmark-container-product-image {
        display: flex;
        flex-direction: column;
        height: 14rem;
        justify-content: center;
        margin: 0 auto;
        width: 14rem;

        img {
            display: block;
            margin: auto;
            width: 10rem;
        }
    }

    .button.bookmark-product-cta {
        align-items: center;
        border-radius: 0;
        display: flex;
        font-size: 0.813rem;
        font-weight: $font-bold;
        justify-content: center;
        margin-top: 0.1rem;
        padding: 0.5rem 0.938rem;
        text-align: center;
        transition: all 150ms ease-in-out;

        &.button-primary {
            background: $color-p300;
            border: 1px solid $color-p300;
            color: $color-n0;

            &:hover {
                background-color: $color-p100;
                box-shadow: 0 0 0 0.063rem $color-p100;
                color: $color-n0;
                padding: 0.5rem 0.938rem;
            }
        }
    }

    .bookmarks-counter {
        color: $color-n0;
        font-size: 0.813rem;
        font-weight: $font-bold;
        position: absolute;
        top: 11%;
    }

    .bookmarks-counter-divider {
        margin: 0.313rem 0;
    }

    .bookmark-tag.example {
        display: none;
    }

    .display-coords {
        display: none;
    }

    .dev-mode {
        .display-coords {
            display: block;
        }
    }

    .show-landing-container {
        padding: 2rem 0;
    }

    .custom-select {
        @include media-breakpoint-down(md) {
            font-size: 0.6rem;
        }
    }

    .collapsible-xl .title {
        @include media-breakpoint-down(md) {
            line-height: 1rem;
        }
    }

    .bookmark-content-wrapper {
        align-items: center;
        background: $color-n0;
        display: flex;
        flex-direction: column;
        height: 25rem;
        justify-content: center;
        width: 25rem;

        @include media-breakpoint-down(md) {
            height: 14rem;
            width: 0;
        }
    }

    .price-list {
        align-items: center;
        display: flex;
        flex-direction: row;
    }

    .price-size,
    .bookmark-product-price {
        font-size: $font-18;
        font-weight: $font-bold;

        &.price-line {
            color: $color-t100;
            font-size: $font-12;
            text-decoration: line-through;
        }
    }


    .shoppable-images-header {
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            align-items: center;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .nav-shoppable-images {
        overflow: auto;
        padding-bottom: $space-8;

        .nav-tabs {
            border: 0;
            justify-content: start;
            margin-top: $space-16;

            .nav-link {
                border: 1px solid $color-n100;
                border-radius: 100px;
                color: $color-t300;
                font-size: $font-14;
                font-weight: $font-medium;
                line-height: 120%;
                margin-right: $space-8;
                padding: $space-8 $space-16;
                white-space: nowrap;

                &.active {
                    background-color: $color-p50;
                    border-color: $color-p400;
                }
            }
        }
    }

    .tab-pane {
        position: relative;
    }

    .cta-shoppable-container {
        bottom: $space-16;
        padding: 0 $space-16;
        position: absolute;
        width: 100%;
    }
}

.full-width-mobile {
    .shoppable-images-wrapper {
        .shoppable-images-header {
            @include media-breakpoint-down(md) {
                padding: 0 $space-16;
            }
        }

        .osf-img-tagger-widget {
            @include media-breakpoint-down(md) {
                padding: 0 $space-8;
            }
        }
    }
}
